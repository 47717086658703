import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
import './App.css';
import 'antd-mobile/dist/antd-mobile.css';

const Auth = lazy(() => import('./pages/auth'));
const Staff = lazy(() => import('./pages/staff'));
const User = lazy(() => import('./pages/user'));

const App = () => (
  <Router>
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route path="/auth" component={Auth}/>
        <Route path="/staff" component={Staff}/>
        <Route path="/user" component={User}/>
      </Switch>
    </Suspense>
  </Router>
);

export default App;
